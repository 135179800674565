<script>
	import Menu from '../lib/components/Menu.svelte';
    import './styles.css';
    import menuLinks from "../lib/json/menu.json";
    import { page } from '$app/stores';
</script>
{#if $page.url.pathname == '/'}
    <div class="app">
        <header class="header">
            <Menu
                telephone={menuLinks.telephone}
                menu={menuLinks.menu}
                email={menuLinks.email}
                mobileMenu={true}
            />
        </header>
        <main>
            <slot />
        </main>
        <footer class="footer">
            <svg 
                class="footer__splash"
                xmlns="http://www.w3.org/2000/svg" 
                width="562" height="290" 
                fill="none"
            >
                <path fill="#04B53D" d="m0 0 204 36c70 12 144 24 193 50 49 25 72 64 95 101l69 106c23 35-561 0-561 0V0Z"/>
            </svg>          
        </footer>
    </div>
{:else}
    <slot/>
{/if}

<style>
    .footer__splash{
        max-size: 100% 120px;
    }
    @media (min-width: 992px){
        .footer__splash{
            max-size: 100%;
        }
    }
</style>